.lfhEjN, .bcMPWx, .fTnbnB {
    max-height: 1400px !important;
    width: 100% !important;
    overflow: auto !important;
    cursor: initial !important;
}

.fTnbnB, .bJCmFu {
    background-color: transparent !important;
    background-image: none !important;
    cursor: default !important;
}

.iBtNbm, .bOTXef {
    min-width: 60px !important;
    background: transparent !important;
}

.sc-AxheI.fDFUjO, .sc-AxheI.jhgduD {
    right: -19.5px !important;
} 
.sc-AxheI.bjfDGw, .sc-AxheI.idmijZ {
    left: -22.5px !important;
}

.welcome, .intro {
    background-color: #b2acfa !important;
}

.reasons {
    background-color: #f2c09c !important;
}

.feedback {
    background-color: #8dd7cf !important;
}

.goodbye, .confirm {
    background-color: #89b8e2 !important;
}

.offer {
    background-color: #fae192 !important;
}

.sortList:before {
    content: "";
    height: 13px;
    width: 13px;
    position: absolute;
    border-radius: 13px;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid #a8a8a8;
    background: #fff;
    z-index: 10;
}

.sortListWrapper:before {
    content: "";
    height: calc(100% - 55px);
    width: 1px;
    background: #bebebe;
    position: absolute;
    left: 7px;
    top: 50%;
    transform: translateY(-50%);
}

circle {
    display: none;
}

.diamond {
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom-color: #4A4A4A;
    position: relative;
    top: -50px;
    margin-left: -12px;
}
.diamond:before {
    content: 'condition';
    position: absolute;
    color: #fff;
    top: 40px;
    z-index: 55;
    left: -30px;
    font-size: 14px;
}
.diamond:after {
    content: '';
    position: absolute;
    left: -50px;
    top: 50px;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-top-color: #4A4A4A;
}

.diamond_off {
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom-color: #bababa;
    position: relative;
    top: -50px;
    margin-left: -12px;
}
.diamond_off:before {
    content: 'condition';
    position: absolute;
    color: #fff;
    top: 40px;
    z-index: 55;
    left: -30px;
    font-size: 14px;
}
.diamond_off:after {
    content: '';
    position: absolute;
    left: -50px;
    top: 50px;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-top-color: #bababa;
}

